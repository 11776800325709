export const LinkedInSvg = () => (
  <svg
    className="w-full h-full"
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3334 6.66699C14.6595 6.66699 15.9312 7.19378 16.8689 8.13146C17.8066 9.06914 18.3334 10.3409 18.3334 11.667V17.5003H15V11.667C15 11.225 14.8244 10.801 14.5119 10.4885C14.1993 10.1759 13.7754 10.0003 13.3334 10.0003C12.8913 10.0003 12.4674 10.1759 12.1549 10.4885C11.8423 10.801 11.6667 11.225 11.6667 11.667V17.5003H8.33337V11.667C8.33337 10.3409 8.86016 9.06914 9.79784 8.13146C10.7355 7.19378 12.0073 6.66699 13.3334 6.66699Z"
      stroke="#202020"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.99996 7.5H1.66663V17.5H4.99996V7.5Z"
      stroke="#202020"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.33329 5.00033C4.25377 5.00033 4.99996 4.25413 4.99996 3.33366C4.99996 2.41318 4.25377 1.66699 3.33329 1.66699C2.41282 1.66699 1.66663 2.41318 1.66663 3.33366C1.66663 4.25413 2.41282 5.00033 3.33329 5.00033Z"
      stroke="#202020"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
