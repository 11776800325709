import { useFormikContext } from "formik";

import { CustomPhoneInput } from "components/CustomPhoneInput";
import { FormItem } from "components/FormItem";
import { Input } from "components/Input";
import { Textarea } from "components/Textarea";
import { Button } from "components/Button";

import { FIELD_TYPES, CONTACT_US_FORM_FIELDS } from "constants/content";
import { LOCALE_STRINGS } from "constants/locales";

export const ContactUsFormFields = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    validateField,
    handleBlur,
    setFieldTouched,
    isSubmitting
  } = useFormikContext();

  const handleFieldBlur = async (e, field) => {
    await setFieldValue(field.key, e.target.value.trim());
    validateField(field.key);
    handleBlur(e);
  };

  return (
    <>
      {CONTACT_US_FORM_FIELDS.map((field) => (
        <FormItem
          key={field.key}
          label={LOCALE_STRINGS[field.label]}
          invalid={errors[field.key] && touched[field.key]}
          errorMessage={errors[field.key]}
          asterisk={field.required}
          className={field.className}
        >
          {field.type === FIELD_TYPES.text && (
            <Input
              type={field.type}
              autoComplete="off"
              name={field.key}
              placeholder={LOCALE_STRINGS[field.placeholder]}
              onChange={handleChange}
              onBlur={(e) => handleFieldBlur(e, field)}
              isInvalid={errors[field.key] && touched[field.key]}
              value={values[field.key]}
              maxLength={field.maxLength}
            />
          )}

          {field.type === FIELD_TYPES.phone && (
            <CustomPhoneInput
              phone={values[field.key]}
              invalid={errors[field.key] && touched[field.key]}
              updatePhone={(value) => {
                setFieldValue(field.key, value);
                setTimeout(() => setFieldTouched(field.key, true), 0);
              }}
              onBlur={() => setFieldTouched(field.key, true)}
              clearField={() => {
                setFieldValue(field.key, "");
              }}
            />
          )}

          {field.type === FIELD_TYPES.textArea && (
            <Textarea
              type={field.type}
              autoComplete="off"
              name={"message"}
              placeholder={LOCALE_STRINGS[field.placeholder]}
              onChange={handleChange}
              onBlur={(e) => handleFieldBlur(e, field)}
              isInvalid={errors[field.key] && touched[field.key]}
              value={values[field.key]}
              maxLength={field.maxLength}
            />
          )}
        </FormItem>
      ))}

      <Button
        text={LOCALE_STRINGS.send}
        type="submit"
        className="mb-10 xl:mb-0"
        isDisabled={isSubmitting}
      />
    </>
  );
};
