import { AboutUsSection } from "pages/Home/components/AboutUsSection";
import { FacingSupplyChainSection } from "pages/Home/components/FacingSupplyChainSection";
import { KeyProductCategoriesSection } from "pages/Home/components/KeyProductCategoriesSection";
import { OurValuesSection } from "pages/Home/components/OurValuesSection";
import { ServicesSection } from "pages/Home/components/ServicesSection";
import { SimplifyingGlobalTradeSection } from "pages/Home/components/SimplifyingGlobalTradeSection";
import { WhyChooseSection } from "pages/Home/components/WhyChooseSection";
import { ContactUsSection } from "pages/Home/components/ContactUsSection";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Layout } from "components/Layout";

export const Home = () => {
  return (
    <>
      <Header />

      <Layout>
        <SimplifyingGlobalTradeSection />
        <AboutUsSection />
        <OurValuesSection />
        <WhyChooseSection />
        <FacingSupplyChainSection />
        <ServicesSection />
        <KeyProductCategoriesSection />
        <ContactUsSection />
        <Footer />
      </Layout>
    </>
  );
};
