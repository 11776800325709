import keyProductCategories from "assets/images/keyProductCategories.jpg";
import { TransparentCheckboxSvg } from "assets/svg/TransparentCheckboxSvg";

import { LOCALE_STRINGS } from "constants/locales";
import { KEY_PRODUCT_SECTIONS } from "constants/content";

import { Section } from "components/Section";
import { NAVIGATION } from "constants/navigation";

const List = ({ items }) => (
  <div className="flex flex-col gap-[6px] ">
    {items.map((item, index) => (
      <div className="flex items-center gap-3" key={index}>
        <div className="h-[18px] w-[18px] xl:h-[28px] xl:w-[28px]">
          <TransparentCheckboxSvg />
        </div>
        <p>{LOCALE_STRINGS[item]}</p>
      </div>
    ))}
  </div>
);
export const KeyProductCategoriesSection = () => {
  return (
    <Section
      id={NAVIGATION.products.id}
      className="mt-10 xl:mt-[100px] flex-col xl:flex-col"
    >
      <div className="flex flex-col xl:flex-row gap-6 xl:gap-[50px]">
        <div className="flex flex-col order-2 xl:order-1 max-w-full xl:max-w-[576px]">
          <h2>{LOCALE_STRINGS.ourKeyProductCategories}</h2>
          <p className="pt-2 pb-6 xl:pt-6 xl:pb-[50px]">
            {LOCALE_STRINGS.atVantage360IncWeAreTrade}
          </p>

          <div className="flex flex-col xl:grid xl:grid-cols-[max-content_auto] gap-4 xl:gap-[70px]">
            {KEY_PRODUCT_SECTIONS.map((section, index) => (
              <div className="flex flex-col" key={index}>
                <h3 className="mb-2 xl:mb-5">
                  {LOCALE_STRINGS[section.title]}
                </h3>

                <List items={section.items} />
              </div>
            ))}
          </div>
        </div>

        <div className="h-[200px] xl:h-[466px] xl:flex-1 order-1 xl:order-2">
          <img
            src={keyProductCategories}
            alt={LOCALE_STRINGS.ourKeyProductCategories}
            className="object-cover object-center w-full h-full"
          />
        </div>
      </div>
    </Section>
  );
};
