import { ToastElement } from "components/ToastElement";
import { SectionProvider } from "context/SectionContext";
import { Home } from "pages/Home";

function App() {
  return (
    <SectionProvider>
      <Home />
      <ToastElement />
    </SectionProvider>
  );
}

export default App;
