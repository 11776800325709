import { CONTACT_INFO_SECTIONS } from "constants/content";
import { LOCALE_STRINGS } from "constants/locales";

const ItemList = ({ items }) =>
  items.map((item, index) => (
    <div key={index} className="flex items-center mb-3">
      {item.icon && <div className="h-5 w-5 mr-[10px]">{item.icon}</div>}

      {item.link ? (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline text-sm xl:text-lg font-normal leading-[18px] xl:leading-[30px] cursor-pointer hover:text-red"
        >
          {LOCALE_STRINGS[item.title]}
        </a>
      ) : (
        <p>{LOCALE_STRINGS[item.title]}</p>
      )}
    </div>
  ));

export const ContactInfo = () => (
  <div className="pl-0 xl:pl-[68px] flex flex-col xl:border-l-2 border-l-borderGray pt-10 xl:pt-0 border-t-borderGray border-t-2 xl:border-t-0">
    {CONTACT_INFO_SECTIONS.map((section, index) => (
      <div key={index} className="flex flex-col mb-6 xl:mb-10">
        <h3 className="mb-2">{LOCALE_STRINGS[section.title]}</h3>
        <ItemList items={section.items} />
      </div>
    ))}
  </div>
);
