export const CheckboxSvg = () => (
  <svg
    className="w-full h-full"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="30"
      height="30"
      rx="6.07046"
      fill="#C10201"
      fill-opacity="0.1"
    />
    <path
      d="M20.0591 11.207L13.1033 18.1628L9.94165 15.0011"
      stroke="#C10201"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
