import { LOCALE_STRINGS } from "constants/locales";
import { SERVICES_ITEMS } from "constants/content";

import { Section } from "components/Section";

import { joinClassNames } from "utils/joinClassNames";
import { NAVIGATION } from "constants/navigation";

const ListItem = ({ title, text, icon, className }) => (
  <div
    className={joinClassNames(
      "flex flex-col xl:flex-row gap-2 xl:gap-[105px] pt-[28px] pb-4 xl:pt-[60px] xl:pb-5 border-b-2 border-borderGray w-full",
      className
    )}
  >
    <div className="w-full xl:w-[352px] flex content-center gap-2">
      <div className="h-6 w-6 xl:h-[30px] xl:w-[30px]"> {icon}</div>
      <h3>{LOCALE_STRINGS[title]}</h3>
    </div>
    <div className="flex-1">
      <p>{LOCALE_STRINGS[text]}</p>
    </div>
  </div>
);

export const ServicesSection = () => {
  return (
    <Section
      id={NAVIGATION.services.id}
      title={LOCALE_STRINGS.services}
      className="flex-col xl:flex-col px-0 xl:px-[115px] mt-10 xl:mt-[100px]"
    >
      <div className="flex flex-col">
        {SERVICES_ITEMS.map((item, index) => (
          <ListItem
            key={index}
            text={item.text}
            title={item.title}
            icon={item.icon}
            className={item.className}
          />
        ))}
      </div>
    </Section>
  );
};
