import { joinClassNames } from "utils/joinClassNames";

export const FormItem = ({
  label,
  invalid,
  errorMessage,
  children,
  className = "",
  labelSuffix = "",
}) => {
  return (
    <div className={joinClassNames("flex flex-col relative", className)}>
      <label className="flex items-center mb-1 xl:mb-2">
        <p>{label}</p>
        {labelSuffix && ` ${labelSuffix}`}
      </label>
      <div
        className={joinClassNames("flex flex-col", invalid ? "form-error" : "")}
      >
        {children}
        {invalid && (
          <p className="text-red text-xs xl:text-sm absolute -bottom-4 xl:-bottom-6">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};
