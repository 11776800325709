import { NAVIGATION } from "constants/navigation";
import { LOCALE_STRINGS } from "constants/locales";

import { joinClassNames } from "utils/joinClassNames";
import { scrollToSection } from "utils/scrollToSection";

import { useSection } from "context/SectionContext";

export const Navigation = ({ className }) => {
  const { setActiveSection } = useSection();
  const navigation = Object.values(NAVIGATION).filter(
    (navItem) => !navItem.isHidden
  );

  return (
    <nav
      className={joinClassNames(
        "flex flex-col xl:flex-row items-center gap-6 xl:gap-10",
        className
      )}
    >
      {navigation.map((item, index) => (
        <a
          key={index}
          href={`#${item.id}`}
          onClick={() => {
            scrollToSection(item.id);
            setActiveSection(item.id);
          }}
          className={joinClassNames(
            "no-underline text-md xl:text-[18px] font-normal leading-[24px] xl:leading-[30px] capitalize hover:text-red"
          )}
        >
          {LOCALE_STRINGS[item.title]}
        </a>
      ))}
    </nav>
  );
};
