export const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 z-50 block xl:flex items-center w-full h-svh top-0 justify-center bg-white xl:bg-black xl:bg-opacity-75">
      <div className="bg-white overflow-hidden rounded-[40px] pt-[30px]  xl:max-h-[70vh] px-8 w-full max-w-[768px] transition-transform transform scale-100">
        <div className="flex justify-end items-center">
          <button
            onClick={onClose}
            className="text-[24px] h-6 text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>

        <div className="flex justify-center items-center mt-4">
          <h3 className="text-[24px] xl:text-[32px]">{title}</h3>
        </div>

        <div className="mt-4 max-h-[90vh] xl:max-h-[58vh] -mr-5 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-200">
          {children}
        </div>
      </div>
    </div>
  );
};
