import { CloseSvg } from "assets/svg/CloseSvg";
import { MenuSvg } from "assets/svg/MenuSvg";

import { Navigation } from "components/Navigation";

export const MobileNavigation = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <button onClick={() => setIsOpen((prev) => !prev)}>
        {isOpen ? <CloseSvg /> : <MenuSvg />}
        {isOpen && (
          <div className="w-full h-svh fixed top-11 pt-[24px] bg-white left-0">
            <Navigation />
          </div>
        )}
      </button>
    </>
  );
};
