import { useState } from "react";

import { Button } from "components/Button";
import { Navigation } from "components/Navigation";
import { Modal } from "components/Modal";
import { PrivacyPolicyContent } from "components/PrivacyPolicyContent";

import verticalLogo from "assets/images/verticalLogo.png";
import { LOCALE_STRINGS } from "constants/locales";
import { NAVIGATION } from "constants/navigation";
import { scrollToSection } from "utils/scrollToSection";

export const Footer = () => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);

  return (
    <>
      <footer className="bg-white relative flex flex-col">
        <div className="xl:h-[74px] flex flex-col xl:flex-row items-center justify-between">
          <img
            src={verticalLogo}
            alt=""
            onClick={() => scrollToSection(NAVIGATION.heroSection.id)}
            className="w-[132px] h-[74px] rounded-none mb-6 xl:mb-0 cursor-pointer"
          />

          <div className="flex-1 ml-0 xl:ml-[96px]">
            <Navigation className="gap-[8px] xl:gap-8" />
          </div>

          <Button
            className="h-auto xl:h-auto w-full xl:w-auto bg-white rounded-[0px] mt-6 xl:mt-0 hover:opacity-100 hover:!bg-[white]"
            textClassName="capitalize text-[16px] xl:text-lg leading-[24px] text-[black] hover:text-red cursor-pointer"
            onClick={() => setIsPrivacyPolicyModalOpen(true)}
            text={LOCALE_STRINGS.privacyPolicy}
          />
        </div>

        <div className="bg-gray h-[54px] relative left-1/2 -translate-x-1/2 w-[99vw] flex items-center justify-center mt-10 xl:mt-6">
          <p className="text-textGray text-lg">
            © {new Date().getFullYear()} {LOCALE_STRINGS.vantage360}
          </p>
        </div>
      </footer>

      <Modal
        isOpen={isPrivacyPolicyModalOpen}
        onClose={() => setIsPrivacyPolicyModalOpen(false)}
        title={LOCALE_STRINGS.privacyPolicy}
      >
        <PrivacyPolicyContent />
      </Modal>
    </>
  );
};
