export const EMAIL_MAX_LENGTH = 129;
export const TEXT_FIELD_MIN_LENGTH = 2;
export const TEXT_FIELD_MAX_LENGTH = 30;
export const PHONE_FIELD_MIN_LENGTH = 9;
export const MESSAGE_MAX_LENGTH = 1000;

export const NOT_ONLY_SPECIAL_CHARACTERS = /[A-Za-z0-9éàèùâêîôûëïüçæœ«»]+/;
export const EMAIL_REG_EXP =
  /^\s*(?!\.)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9._+-]{1,64}@([a-zA-Z0-9.-]{1,62}\.[a-zA-Z]{2,})\s*$/;

export const LENGTH_ERROR = ({
  field,
  mustBeFrom,
  min,
  to,
  max,
  characters,
}) => {
  return `${field} ${mustBeFrom} ${min} ${to} ${max} ${characters}`;
};
