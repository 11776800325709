import { LOCALE_STRINGS } from "constants/locales";
import {
  EMAIL_MAX_LENGTH,
  EMAIL_REG_EXP,
  LENGTH_ERROR,
  MESSAGE_MAX_LENGTH,
  NOT_ONLY_SPECIAL_CHARACTERS,
  PHONE_FIELD_MIN_LENGTH,
  TEXT_FIELD_MAX_LENGTH,
  TEXT_FIELD_MIN_LENGTH,
} from "constants/validations";
import * as Yup from "yup";

export const contactUsValidationSchema = () => {
  const lengthErrorParams = {
    mustBeFrom: LOCALE_STRINGS.mustBeFrom,
    min: TEXT_FIELD_MIN_LENGTH,
    to: LOCALE_STRINGS.to,
    max: TEXT_FIELD_MAX_LENGTH,
    characters: LOCALE_STRINGS.characters,
  };

  return Yup.object().shape({
    message: Yup.string()
      .test(
        "errorNotOnlySpecialCharacters",
        LOCALE_STRINGS.errorNotOnlySpecialCharacters,
        function (value) {
          return NOT_ONLY_SPECIAL_CHARACTERS.test(value);
        }
      )
      .min(TEXT_FIELD_MIN_LENGTH, LOCALE_STRINGS.errorMessageLength)
      .max(
        MESSAGE_MAX_LENGTH,
        LENGTH_ERROR({
          ...lengthErrorParams,
          field: LOCALE_STRINGS.errorMessageLength,
        })
      )
      .required(`${LOCALE_STRINGS.errorMessageRequired}`),
    email: Yup.string()
      .test("invalidEmail", LOCALE_STRINGS.invalidEmail, function (value) {
        return EMAIL_REG_EXP.test(value);
      })
      .max(
        EMAIL_MAX_LENGTH,
        `${LOCALE_STRINGS.email} ${LOCALE_STRINGS.errorMustBeNotLongerThan129Chars}`
      )
      .required(`${LOCALE_STRINGS.email} ${LOCALE_STRINGS.isRequired}`),
    contact: Yup.string()
      .min(PHONE_FIELD_MIN_LENGTH, LOCALE_STRINGS.errorPhoneMinLength)
      .required(`${LOCALE_STRINGS.phoneNumber} ${LOCALE_STRINGS.isRequired}`),
  });
};
