export const NAVIGATION = {
  heroSection: { id: "hero", isHidden: true },
  aboutUs: {
    id: "aboutUs",
    title: "aboutUs",
  },
  ourValues: {
    id: "ourValues",
    title: "ourValues",
  },
  whyChooseUp: {
    id: "whyChooseUp",
    title: "whyChooseUp",
  },
  services: {
    id: "services",
    title: "services",
  },
  products: {
    id: "products",
    title: "products",
  },
  contactUs: {
    id: "contactUs",
    title: "contactUs",
  },
};
