export const HandshakeSvg = () => {
  return (
    <svg
      className="w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="82" height="82" rx="16" fill="#ECEFF2" />
      <path
        d="M39.3333 49.3337L42.6666 52.667C42.9949 52.9953 43.3846 53.2557 43.8136 53.4334C44.2425 53.6111 44.7023 53.7025 45.1666 53.7025C45.6309 53.7025 46.0906 53.6111 46.5196 53.4334C46.9485 53.2557 47.3383 52.9953 47.6666 52.667C47.9949 52.3387 48.2553 51.9489 48.433 51.52C48.6107 51.091 48.7021 50.6313 48.7021 50.167C48.7021 49.7027 48.6107 49.243 48.433 48.814C48.2553 48.3851 47.9949 47.9953 47.6666 47.667"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.3332 44.3322L48.4999 48.4989C49.1629 49.1619 50.0622 49.5344 50.9999 49.5344C51.9376 49.5344 52.8368 49.1619 53.4999 48.4989C54.1629 47.8358 54.5354 46.9366 54.5354 45.9989C54.5354 45.0612 54.1629 44.1619 53.4999 43.4989L47.0332 37.0322C46.0957 36.0959 44.8249 35.57 43.4999 35.57C42.1749 35.57 40.9041 36.0959 39.9666 37.0322L38.4999 38.4989C37.8368 39.1619 36.9376 39.5344 35.9999 39.5344C35.0622 39.5344 34.1629 39.1619 33.4999 38.4989C32.8368 37.8358 32.4644 36.9366 32.4644 35.9989C32.4644 35.0612 32.8368 34.1619 33.4999 33.4989L38.1832 28.8156C39.7036 27.2991 41.6864 26.3331 43.8176 26.0705C45.9489 25.8079 48.1068 26.2636 49.9499 27.3656L50.7332 27.8322C51.4429 28.2605 52.2866 28.4091 53.0999 28.2489L55.9999 27.6656"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.9999 26L57.6666 44.3333H54.3333"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.9999 26L24.3333 44.3333L35.1666 55.1667C35.8296 55.8297 36.7289 56.2022 37.6666 56.2022C38.6043 56.2022 39.5035 55.8297 40.1666 55.1667C40.8296 54.5036 41.2021 53.6043 41.2021 52.6667C41.2021 51.729 40.8296 50.8297 40.1666 50.1667"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26 27.667H39.3333"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
