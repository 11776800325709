import { Section } from "components/Section";
import { Button } from "components/Button";

import { LOCALE_STRINGS } from "constants/locales";
import { NAVIGATION } from "constants/navigation";

import { scrollToSection } from "utils/scrollToSection";
import { MapSvg } from "assets/svg/MapSvg";

export const SimplifyingGlobalTradeSection = () => {
  return (
    <Section id={NAVIGATION.heroSection.id} className="mt-2 xl:mt-[60px] items-center gap-6 xl:gap-4">
      <div className="flex flex-col">
        <h1 className="max-w-full xl:max-w-[730px]">
          {LOCALE_STRINGS.simplifyingGlobalTrade}
        </h1>

        <p className="mt-4 xl:6 max-w-full xl:max-w-[658px]">
          {LOCALE_STRINGS.vantage360IncIsALeadingCompany}
        </p>

        <Button
          text={LOCALE_STRINGS.contactUs}
          className="mt-6 xl:mt-[50px]"
          onClick={() => scrollToSection(NAVIGATION.contactUs.id)}
        />
      </div>

      <div className="h-[152px] xl:h-[354px] ml-0">
        <MapSvg/>
      </div>
    </Section>
  );
};
