import { Formik, Form as FormikForm } from "formik";

export const Form = ({
  handleFormSubmit,
  initialValues,
  children,
  validationSchema,
}) => {
  return (
    <div className="w-full">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldValue, setFieldTouched }) =>
          handleFormSubmit(values, {
            setSubmitting,
            setFieldValue,
            setFieldTouched,
          })
        }
      >
        {() => {
          return (
            <FormikForm>
              <div className="w-full">{children}</div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};
