import { Section } from "components/Section";
import { Card } from "components/Card";

import { LOCALE_STRINGS } from "constants/locales";
import { ABOUT_US_CARDS } from "constants/content";
import { NAVIGATION } from "constants/navigation";

const CardContent = ({ title, icon, text }) => (
  <div className="flex flex-col justify-center items-center">
    <div className="h-[50px] w-[50px] xl:h-[82px] xl:w-[82px]"> {icon}</div>
    <h3 className="text-center mt-6 mb-2 xl:mb-4">{LOCALE_STRINGS[title]}</h3>
    <p className="text-center">{LOCALE_STRINGS[text]}</p>
  </div>
);

export const AboutUsSection = () => {
  return (
    <Section id={NAVIGATION.aboutUs.id} title={LOCALE_STRINGS.aboutUs} className="flex-col xl:flex-col">
      <div className="flex flex-col xl:grid xl:grid-cols-3 gap-4 xl:gap-5">
        {ABOUT_US_CARDS.map((card, index) => (
          <Card className="h-[202px] xl:h-[328px]">
            <CardContent
              key={index}
              text={card.text}
              title={card.title}
              icon={card.icon}
            />
          </Card>
        ))}
      </div>
    </Section>
  );
};
