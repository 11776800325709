import { Form } from "components/Form";
import { ContactUsFormFields } from "pages/Home/components/ContactUsSection/ContactUsFormFields";

import { contactUsValidationSchema } from "utils/contactUsValidationSchema";
import { submitContactUsForm } from "utils/submitContactUsForm";

export const ContactUsForm = () => {
  const initialValues = {
    email: "",
    contact: "",
    message: "",
  };
  const validationSchema = contactUsValidationSchema();

  return (
    <Form
      initialValues={initialValues}
      handleFormSubmit={(
        values,
        { setSubmitting, setFieldValue, setFieldTouched }
      ) => {
        submitContactUsForm(
          values,
          setSubmitting,
          setFieldValue,
          setFieldTouched
        );
      }}
      validationSchema={validationSchema}
    >
      <div className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-5 gap-y-4 xl:gap-y-6 pr-0 xl:pr-[68px] mb-4 xl:mb-6">
        <ContactUsFormFields />
      </div>
    </Form>
  );
};
