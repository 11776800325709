export const EmailSvg = () => (
  <svg
    className="w-full h-full"
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6666 3.33301H3.33329C2.41282 3.33301 1.66663 4.0792 1.66663 4.99967V14.9997C1.66663 15.9201 2.41282 16.6663 3.33329 16.6663H16.6666C17.5871 16.6663 18.3333 15.9201 18.3333 14.9997V4.99967C18.3333 4.0792 17.5871 3.33301 16.6666 3.33301Z"
      stroke="#202020"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3333 5.83301L10.8583 10.583C10.601 10.7442 10.3036 10.8297 9.99996 10.8297C9.69636 10.8297 9.3989 10.7442 9.14163 10.583L1.66663 5.83301"
      stroke="#202020"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
