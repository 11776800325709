import { ToastService } from "components/ToastElement";
import { CONTACT_US_URL } from "constants/api";
import { LOCALE_STRINGS } from "constants/locales";

export const submitContactUsForm = async (
  values,
  setSubmitting,
  setFieldValue,
  setFieldTouched
) => {
  const body = JSON.stringify({
    email: values.email.trim(),
    contact: `+${values.contact.trim()}`,
    message: values.message.trim(),
  });

  return fetch(CONTACT_US_URL, {
    method: "POST",
    body,
    headers: {
      Accept: "application/json",
      "Content-type": "application/json;charset=utf-8",
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then(() => {
      setFieldValue("message", "");
      setFieldTouched("message", false);
      ToastService.success(LOCALE_STRINGS.contactUsSuccessMessage);
    })
    .catch((response) => {
      response.json().then((json) => {
        json.errors?.forEach((err) => ToastService.error(err.message));
      });
    })
    .finally(() => setSubmitting(false));
};
