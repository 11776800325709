import React, { createContext, useState, useContext, useEffect } from "react";

const SectionContext = createContext(undefined);

export const SectionProvider = ({ children }) => {
  const activeSectionId = window.location.hash?.slice(1);

  const [activeSection, setActiveSection] = useState(activeSectionId);

  useEffect(() => {
    setActiveSection(activeSectionId);
  }, [activeSectionId]);

  return (
    <SectionContext.Provider value={{ activeSection, setActiveSection }}>
      {children}
    </SectionContext.Provider>
  );
};

export const useSection = () => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error("useSection must be used within a SectionProvider");
  }
  return context;
};
