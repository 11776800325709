import { PRIVACY_POLICY_CONTENT } from "constants/content";
import { LOCALE_STRINGS } from "constants/locales";
import { SOCIAL_LINKS } from "constants/socialLinks";

import { joinClassNames } from "utils/joinClassNames";

import logo from "assets/images/logo.png";

const List = ({ list, type = "dotted" }) => {
  const listStyle = type === "numbered" ? "list-decimal" : "list-disc";

  return (
    <ul className={joinClassNames("pl-5 mt-2", listStyle)}>
      {list.map((item, idx) => (
        <li key={idx}>
          <p className="font-light xl:font-normal">{LOCALE_STRINGS[item]}</p>
        </li>
      ))}
    </ul>
  );
};

const ParagraphList = ({ sections }) => {
  return sections?.map((section) => (
    <p className="mt-3 font-light xl:font-normal">{LOCALE_STRINGS[section]}</p>
  ));
};

const LinkParagraph = ({ textBefore, textAfter, link, linkTitle }) => (
  <p className="mb-6 font-light xl:font-normal">
    {textBefore}
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="underline"
    >
      {linkTitle}
    </a>
    {textAfter}
  </p>
);

const Footer = () => (
  <div className="flex flex-col gap-5 w-full items-center justify-center mb-10">
    <img
      src={logo}
      alt=""
      className="h-[22px] w-[120px] xl:h-7 xl:w-[158px] rounded-[0px]"
    />
    <a
      href={SOCIAL_LINKS.email}
      target="_blank"
      rel="noopener noreferrer"
      className="underline font-bold text-md xl:text-[18px]"
    >
      {LOCALE_STRINGS.contactEmail}
    </a>
    <p className="text-textGray text-[12px] xl:text-lg font-light xl:font-normal">
      © {new Date().getFullYear()} {LOCALE_STRINGS.vantage360}
    </p>
  </div>
);

export const PrivacyPolicyContent = () => {
  return (
    <>
      <LinkParagraph
        textBefore={LOCALE_STRINGS.atVantage360IncAccessibleFrom}
        textAfter={LOCALE_STRINGS.oneOfOurMainPriorities}
        link={SOCIAL_LINKS.companyLink}
        linkTitle={LOCALE_STRINGS.vantage360Com}
      />

      {PRIVACY_POLICY_CONTENT.map((section, index) => (
        <div key={index} className="mb-8">
          <h3 className="font-bold">{LOCALE_STRINGS[section.title]}</h3>

          <ParagraphList sections={section.sections} />

          {section.list && <List list={section.list} type={section.listType} />}
        </div>
      ))}

      <h3 className="mb-3">{LOCALE_STRINGS.contactUs}</h3>
      <LinkParagraph
        textBefore={LOCALE_STRINGS.ifYouHaveAdditionalQuestions}
        textAfter="."
        link={SOCIAL_LINKS.email}
        linkTitle={LOCALE_STRINGS.contactEmail}
      />

      <Footer />
    </>
  );
};
