import { ContainerSvg } from "assets/svg/ContainerSvg";
import { HandshakeSvg } from "assets/svg/HandshakeSvg";
import { RocketSvg } from "assets/svg/RocketSvg";
import { StatysticsSvg } from "assets/svg/StatysticsSvg";
import { ShipSvg } from "assets/svg/ShipSvg";
import { ProtectionSvg } from "assets/svg/ProtectionSvg";
import { GlobeSvg } from "assets/svg/GlobeSvg";
import { LinkedInSvg } from "assets/svg/LinkedInSvg";
import { EmailSvg } from "assets/svg/EmailSvg";

import { EMAIL_MAX_LENGTH, MESSAGE_MAX_LENGTH } from "constants/validations";
import { SOCIAL_LINKS } from "constants/socialLinks";

export const ABOUT_US_CARDS = [
  {
    title: "whatWeDo",
    icon: <ContainerSvg />,
    text: "weStreamlineSupplyChains",
  },
  {
    title: "ourCommitment",
    icon: <HandshakeSvg />,
    text: "withFullAccontability",
  },
  {
    title: "ourMission",
    icon: <RocketSvg />,
    text: "beATrustedLeader",
  },
];

export const OUR_VALUES_CARDS = [
  {
    title: "integrity",
    text: "weConductBusiness",
    number: "01",
  },
  {
    title: "quality",
    text: "wePrioritizeTheQuality",
    number: "02",
  },
  {
    title: "customerFocus",
    text: "weAreDedicatedToMeeting",
    number: "03",
  },
  {
    title: "innovation",
    text: "weExploreAndImplementSolutions",
    number: "04",
  },
  {
    title: "sustainability",
    text: "weActivelySeekOut",
    number: "05",
    className: "col-span-2",
  },
];

export const WHY_CHOOSE_ITEMS = [
  {
    title: "globalReach",
    text: "weHaveAStrongPresence",
  },
  {
    title: "experiencedTeam",
    text: "ourTeamOfExperts",
  },
  {
    title: "strongRelationships",
    text: "weMaintainStrongRelationships",
  },
  {
    title: "commitmentToSustainability",
    text: "weAreCommitedToSustainable",
  },
];

export const SERVICES_ITEMS = [
  {
    title: "sourcing",
    text: "byLeveragingOurGlobalNetwork",
    icon: <GlobeSvg />,
    className: "!pt-[0px] xl:!pt-[0px]",
  },
  {
    title: "riskManagement",
    text: "weProvideComprehensiveRisk",
    icon: <ProtectionSvg />,
  },
  {
    title: "marketAnalysisAndInsights",
    text: "weProvideOurClients",
    icon: <StatysticsSvg />,
  },
  {
    title: "logisticsAndTransportation",
    text: "wePossessComprehensiveExpertise",
    icon: <ShipSvg />,
  },
];

export const KEY_PRODUCT_SECTIONS = [
  {
    title: "softCommodities",
    items: [
      "cashewNuts",
      "naturalRubber",
      "coffee",
      "cocoaProducts",
      "poultry",
    ],
  },
  {
    title: "hardCommodities",
    items: [
      "steelProducts",
      "aluminiumIngots",
      "aluminiumProfiles",
      "buildingGlass",
      "plasticsAndPolymers",
    ],
  },
];

export const CONTACT_INFO_SECTIONS = [
  {
    title: "ourOffice",
    items: [{ title: "officeAddress", link: SOCIAL_LINKS.address }],
  },
  {
    title: "contacts",
    items: [
      { title: "contactEmail", icon: <EmailSvg />, link: SOCIAL_LINKS.email },
      { title: "linkedIn", icon: <LinkedInSvg />, link: SOCIAL_LINKS.linkedIn },
    ],
  },
];

export const FIELD_TYPES = {
  text: "text",
  phone: "phone",
  textArea: "textArea",
};

export const CONTACT_US_FORM_FIELDS = [
  {
    key: "email",
    label: "email",
    placeholder: "yourEmail",
    type: FIELD_TYPES.text,
    maxLength: EMAIL_MAX_LENGTH,
    required: true,
  },
  {
    key: "contact",
    label: "contact",
    type: FIELD_TYPES.phone,
    required: true,
  },
  {
    key: "message",
    label: "message",
    placeholder: "yourMessage",
    type: FIELD_TYPES.textArea,
    maxLength: MESSAGE_MAX_LENGTH,
    required: true,
    className: "col-span-2",
  },
];

export const PRIVACY_POLICY_CONTENT = [
  {
    title: "consent",
    sections: ["byUsingOurWebsite"],
    listType: "dotted",
    list: ["theConditionsSetOut", "theConditionUseAndRetention"],
  },
  {
    title: "informationWeCollect",
    sections: [
      "thePersonalInformationThatYouAreAsked",
      "whenYouRegisterForAnAccount",
    ],
  },
  {
    title: "howToUseYourInformation",
    sections: ["weUseTheInformationWeCollect"],
    listType: "numbered",
    list: [
      "provideOperateAndMaintain",
      "improvePersonalizeAndExpand",
      "understandAndAnalyzeHowToUse",
      "developNewProducts",
      "communicateWithYou",
      "sendYouEmails",
    ],
  },
  {
    title: "logFiles",
    sections: ["vantage360FollowsAStandart"],
  },
  {
    title: "cookies",
    sections: ["likeAnyOtherWebsite"],
  },
  {
    title: "GDPRDataProtectionRights",
    sections: [
      "weWouldLikeToMakeSureYouAreFullyAware",
      "theRightToRectification",
      "theRightToErasure",
      "theRightToRestrict",
      "theRightToObjectToProcessing",
      "theRightToDataPortability",
      "ifYouMakeARequest",
    ],
  },
  {
    title: "changesToThisPrivacyPolicy",
    sections: ["weMayUpdateOurPrivacyPolicy"],
  },
];
