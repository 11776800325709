export const ContainerSvg = () => {
  return (
    <svg
      className="w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="82" height="82" rx="16" fill="#ECEFF2" />
      <path
        d="M57.6666 33.8335C57.6666 32.8335 56.9999 31.8335 56.3333 31.3335L45.8333 24.8335C45.4014 24.588 44.9133 24.459 44.4166 24.459C43.9199 24.459 43.4317 24.588 42.9999 24.8335L25.8333 34.8335C24.9999 35.1668 24.3333 36.1668 24.3333 37.1668V48.1668C24.3333 49.0002 24.9999 50.1668 25.6666 50.6668L36.1666 57.1668C36.5984 57.4123 37.0866 57.5413 37.5833 57.5413C38.0799 57.5413 38.5681 57.4123 38.9999 57.1668L56.1666 47.1668C56.9999 46.6668 57.6666 45.5002 57.6666 44.6668V33.8335Z"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.6669 57.4993V44.3327L24.5002 36.166"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.6667 44.333L57.5001 32.833"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.3333 54V40.5"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51 50.167V36.667"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
