import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TOAST_CLOSE_AFTER = 5000;

export const SuccessMessage = ({ message }) => {
  return <section className="toastMessage">{message}</section>;
};

export const ErrorMessage = ({ message }) => {
  return <section className="toastMessage">{message}</section>;
};

export const ToastService = {
  success: (message) => {
    toast.success(<SuccessMessage message={message} />);
  },
  error: (message) => {
    toast.error(<ErrorMessage message={message} />);
  },
};

const toastStyle = {
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  padding: "10px 12px 10px 6px",
  gap: "8px",
  backgroundColor: "#374151",
};

export const ToastElement = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={TOAST_CLOSE_AFTER}
      hideProgressBar
      theme="dark"
      toastStyle={toastStyle}
    />
  );
};
