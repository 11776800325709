import { joinClassNames } from "utils/joinClassNames";

export const Card = ({ className, children }) => {
  return (
    <div
      className={joinClassNames(
        "w-full bg-gray rounded-[32px] px-4 py-5 xl:px-[30px] xl:py-10",
        className
      )}
    >
      {children}
    </div>
  );
};
