export const TransparentCheckboxSvg = () => (
  <svg
    className="w-full h-full"
    width="100%"
    height="100%"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3334 7L10.5001 19.8333L4.66675 14"
      stroke="#C10201"
      stroke-width="2.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
