import { joinClassNames } from "utils/joinClassNames";

export const Section = ({
  title = "",
  titleClassName = "",
  className = "",
  id,
  children,
}) => {
  return (
    <section
      className={joinClassNames(
        "flex flex-col xl:flex-row mt-[40px] xl:mt-[80px] scroll-mt-11 xl:scroll-mt-[60px]",
        className
      )}
      id={id}
    >
      {title && (
        <div className="flex justify-center w-full mb-6 xl:mb-10">
          <h2 className={joinClassNames("text-center", titleClassName)}>
            {title}
          </h2>
        </div>
      )}

      {children}
    </section>
  );
};
