export const ProtectionSvg = () => (
  <svg
    className="w-full h-full"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 16.2506C25 22.5006 20.625 25.6256 15.425 27.4381C15.1527 27.5303 14.8569 27.5259 14.5875 27.4256C9.375 25.6256 5 22.5006 5 16.2506V7.50056C5 7.16904 5.1317 6.8511 5.36612 6.61668C5.60054 6.38226 5.91848 6.25056 6.25 6.25056C8.75 6.25056 11.875 4.75056 14.05 2.85056C14.3148 2.62431 14.6517 2.5 15 2.5C15.3483 2.5 15.6852 2.62431 15.95 2.85056C18.1375 4.76306 21.25 6.25056 23.75 6.25056C24.0815 6.25056 24.3995 6.38226 24.6339 6.61668C24.8683 6.8511 25 7.16904 25 7.50056V16.2506Z"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.25 15L13.75 17.5L18.75 12.5"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
