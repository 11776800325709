import { Section } from "components/Section";

import { LOCALE_STRINGS } from "constants/locales";
import { NAVIGATION } from "constants/navigation";

import { ContactInfo } from "pages/Home/components/ContactUsSection/ContactInfo"
import { ContactUsForm } from "pages/Home/components/ContactUsSection/ContactUsForm";

export const ContactUsSection = () => {
  return (
    <Section
      id={NAVIGATION.contactUs.id}
      title={LOCALE_STRINGS.contactUs}
      className="xl:flex-col mt-10 xl:mt-[100px] pb-[30px] xl:pb-[85px]"
    >
      <div className="flex-col xl:grid xl:grid-cols-[555px_auto] pl-0 pr-0 xl:pl-[116px] xl:pr-[56px]">
        <ContactUsForm />
        <ContactInfo />
      </div>
    </Section>
  );
};
