export const RocketSvg = () => {
  return (
    <svg
      className="w-full h-full"
      width="100%"
      height="100%"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="82" height="82" rx="16" fill="#ECEFF2" />
      <path
        d="M28.5 48.4996C26 50.5996 25.1666 56.8329 25.1666 56.8329C25.1666 56.8329 31.4 55.9996 33.5 53.4996C34.6833 52.0996 34.6666 49.9496 33.35 48.6496C32.7021 48.0312 31.8488 47.674 30.9537 47.6463C30.0586 47.6186 29.1848 47.9225 28.5 48.4996Z"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41 46.0001L36 41.0001C36.8869 38.6991 38.0037 36.4935 39.3333 34.4168C41.2753 31.3117 43.9794 28.7552 47.1883 26.9902C50.3973 25.2253 54.0044 24.3107 57.6667 24.3334C57.6667 28.8668 56.3667 36.8334 47.6667 42.6668C45.5615 43.998 43.3281 45.1147 41 46.0001Z"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36 40.9999H27.6666C27.6666 40.9999 28.5833 35.9499 31 34.3332C33.7 32.5332 39.3333 34.3332 39.3333 34.3332"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41 46.0003V54.3337C41 54.3337 46.05 53.417 47.6667 51.0003C49.4667 48.3003 47.6667 42.667 47.6667 42.667"
        stroke="#202020"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
