import { useState } from "react";

import { MobileNavigation } from "components/MobileNavigation";
import { Navigation } from "components/Navigation";

import logo from "assets/images/logo.png";
import { scrollToSection } from "utils/scrollToSection";
import { NAVIGATION } from "constants/navigation";
import { joinClassNames } from "utils/joinClassNames";

export const Header = () => {
  const [isOpenMobileNavigation, setIsOpenMobileNavigation] = useState(false);

  return (
    <header
      className={joinClassNames(
        "sticky top-0 z-50 bg-gray shadow-lg shadow-[#9F9F9F]/30 relative h-11 xl:h-[60px] flex items-center justify-between xl:justify-start",
        isOpenMobileNavigation && "shadow-none"
      )}
    >
      <div className="px-4 xl:px-10 w-full max-w-[1440px] mx-auto flex items-center justify-between xl:justify-start">
        <img
          src={logo}
          alt=""
          onClick={() => scrollToSection(NAVIGATION.heroSection.id)}
          className="h-5 w-[110px] xl:h-7 xl:w-[158px] mr-0 xl:mr-[145px] cursor-pointer"
        />

        <div className="hidden xl:flex">
          <Navigation />
        </div>

        <div className="flex xl:hidden">
          <MobileNavigation
            isOpen={isOpenMobileNavigation}
            setIsOpen={setIsOpenMobileNavigation}
          />
        </div>
      </div>
    </header>
  );
};
