import whyChoose from "assets/images/whyChoose.jpg";
import { CheckboxSvg } from "assets/svg/CheckboxSvg";

import { LOCALE_STRINGS } from "constants/locales";
import { WHY_CHOOSE_ITEMS } from "constants/content";

import { Section } from "components/Section";
import { NAVIGATION } from "constants/navigation";

const ListItem = ({ title, text }) => (
  <div className="flex gap-6">
    <div className="mt-[6px]">
      <div className="w-6 h-6 xl:w-[30px] xl:h-[30px]">
        <CheckboxSvg />
      </div>
    </div>

    <div className="flex flex-col gap-1">
      <h3>{LOCALE_STRINGS[title]}</h3>
      <p>{LOCALE_STRINGS[text]}</p>
    </div>
  </div>
);

export const WhyChooseSection = () => {
  return (
    <Section
      id={NAVIGATION.whyChooseUp.id}
      title={LOCALE_STRINGS.whyChooseVantage360}
      className="flex-col xl:flex-col"
      titleClassName="max-w-[204px] xl:max-w-full"
    >
      <div className="flex flex-col xl:flex-row gap-4 xl:gap-10 ">
        <div className="h-[170px] xl:h-[434px] xl:flex-1 order-2 xl:order-1">
          <img
            src={whyChoose}
            alt={LOCALE_STRINGS.whyChooseVantage360}
            className="object-cover object-center-top w-full h-full"
          />
        </div>

        <div className="flex flex-col gap-4 xl:gap-6 max-w-full xl:max-w-[556px] order-1 xl:order-2">
          {WHY_CHOOSE_ITEMS.map((item, index) => (
            <ListItem key={index} text={item.text} title={item.title} />
          ))}
        </div>
      </div>
    </Section>
  );
};
