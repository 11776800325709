import { joinClassNames } from "utils/joinClassNames";

export const Button = ({
  text = "",
  textClassName = "",
  type = "button",
  className = "",
  isDisabled = false,
  onClick = () => {},
}) => {
  return (
    <button
      className={joinClassNames(
        "h-11 xl:h-[64px] w-full xl:w-[230px] bg-red hover:bg-semiDarkRed active:bg-darkRed rounded-[100px] flex items-center justify-center",
        isDisabled && "opacity-75",
        className
      )}
      type={type}
      disabled={isDisabled}
      onClick={onClick}
    >
      <span className={joinClassNames(textClassName || "text-white")}>
        {text}
      </span>
    </button>
  );
};
