import { Card } from "components/Card";
import { Section } from "components/Section";

import { OUR_VALUES_CARDS } from "constants/content";
import { LOCALE_STRINGS } from "constants/locales";

import { joinClassNames } from "utils/joinClassNames";
import ourValues from "assets/images/ourValues.jpg";
import { NAVIGATION } from "constants/navigation";

const CardContent = ({ title, number, text }) => (
  <div className="flex flex-col">
    <div className="flex gap-2 items-center">
      <span className="text-lg xl:text-2xl leading-[34px] xl:leading-[26px] font-bold text-red">
        {number}
      </span>
      <h3 className="text-center">{LOCALE_STRINGS[title]}</h3>
    </div>
    <p className="mt-2 xl:mt-4">{LOCALE_STRINGS[text]}</p>
  </div>
);

export const OurValuesSection = () => {
  return (
    <Section id={NAVIGATION.ourValues.id} title={LOCALE_STRINGS.ourValues} className="flex-col xl:flex-col">
      <div className="flex flex-col xl:flex-row gap-4 xl:gap-10">
        <div className="max-w-full xl:max-w-[730px] flex flex-col xl:grid xl:grid-cols-2 gap-5">
          {OUR_VALUES_CARDS.map((card) => (
            <Card
              key={card.number}
              className={joinClassNames(
                "h-max xl:h-[150px] px-4 py-6 xl:!px-[24px] xl:py-5",
                card.className
              )}
            >
              <CardContent
                text={card.text}
                title={card.title}
                number={card.number}
                className={card.className}
              />
            </Card>
          ))}
        </div>

        <div className="h-[240px] xl:h-[490px] ml-0">
          <img
            src={ourValues}
            alt={LOCALE_STRINGS.ourValues}
            className="object-cover object-right-top sm:object-[0px_-50px] md:object-[0px_-70px] xl:object-right-top w-full h-full"
          />
        </div>
      </div>
    </Section>
  );
};
