export const ShipSvg = () => (
  <svg
    className="w-full h-full"
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 12.7363V17.5001"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 2.5V6.25"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.75 16.25V8.75C23.75 8.08696 23.4866 7.45107 23.0178 6.98223C22.5489 6.51339 21.913 6.25 21.25 6.25H8.75C8.08696 6.25 7.45107 6.51339 6.98223 6.98223C6.51339 7.45107 6.25 8.08696 6.25 8.75V16.25"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.225 25.0004C25.5696 22.7311 26.2697 20.138 26.25 17.5004L16.015 12.9516C15.6954 12.8097 15.3496 12.7363 15 12.7363C14.6503 12.7363 14.3045 12.8097 13.985 12.9516L3.74997 17.5004C3.6921 21.055 4.94212 24.507 7.26247 27.2004"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 26.25C3.25 26.875 4 27.5 5.625 27.5C8.75 27.5 8.75 25 11.875 25C13.5 25 14.25 25.625 15 26.25C15.75 26.875 16.5 27.5 18.125 27.5C21.25 27.5 21.25 25 24.375 25C26 25 26.75 25.625 27.5 26.25"
      stroke="#202020"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
