import { joinClassNames } from "utils/joinClassNames";

export const Textarea = ({
  type = "text",
  autoComplete = "off",
  name,
  placeholder = "",
  onChange,
  onBlur,
  value,
  placeholderSuffix = "",
  isInvalid = false,
  maxLength,
}) => {
  const placeholderString = placeholderSuffix
    ? `${placeholder} ${placeholderSuffix}`
    : placeholder;

  return (
    <textarea
      name={name}
      className={joinClassNames("customInput", isInvalid && "border-red")}
      placeholder={placeholderString}
      type={type}
      autoComplete={autoComplete}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      maxLength={maxLength}
    />
  );
};
