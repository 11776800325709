import { Section } from "components/Section";
import { Button } from "components/Button";

import facingSupplyChain from "assets/images/facingSupplyChain.png";
import { LOCALE_STRINGS } from "constants/locales";
import { scrollToSection } from "utils/scrollToSection";
import { NAVIGATION } from "constants/navigation";

export const FacingSupplyChainSection = () => {
  return (
    <Section className="relative h-[272px] xl:h-[376px] w-full overflow-hidden mt-10 xl:mt-[100px]">
      <img
        src={facingSupplyChain}
        alt={LOCALE_STRINGS.facingSupplyChainHurdles}
        className="absolute inset-0 w-full h-full object-cover object-left"
      />
      <div className="absolute inset-0 bg-mobile-gradient xl:bg-custom-gradient rounded-[40px]" />

      <div className="relative z-10 flex flex-col items-start justify-center h-full px-4 xl:px-[94px] py-6 xl:py-20 text-white">
        <h2 className="normal-case max-w-[180px] xl:max-w-full">
          {LOCALE_STRINGS.facingSupplyChainHurdles}
        </h2>
        <p className="mt-4 mb-6 xl:mb-8 max-w-[200px] xl:max-w-[532px]">
          {LOCALE_STRINGS.bookACallWithOurExperts}
        </p>
        <Button
          text={LOCALE_STRINGS.contactUs}
          onClick={() => scrollToSection(NAVIGATION.contactUs.id)}
        />
      </div>
    </Section>
  );
};
